import { Component, OnInit } from '@angular/core';
import { AdminUsersService } from './../admin-users.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit {
  page = 1;
  search = '';
  searchFormControl = new FormControl();
  users: any = [];
  count: number = 0;
  constructor(public adminUsersService: AdminUsersService) {}

  ngOnInit(): void {
    this.loadUsers();
    this.adminUsersService.data$.subscribe((users: any) => {
      this.users = users.results;
      this.count = users.count;
    });
    this.searchFormControl.valueChanges
      .pipe(debounceTime(400))
      .subscribe((value) => {
        this.setSearch(value);
      });
  }
  loadUsers() {
    this.adminUsersService
      .paginate(this.page - 1, this.search)
      .subscribe((data) => {
      });
  }
  setPage(page: number) {
    this.page = page;
    this.loadUsers();
    return true;
  }

  setSearch(search: string) {
    this.setPage(1);
    if (search.length == 0) {
      this.search = undefined;
    }
    this.search = search;
    this.loadUsers();
  }
  setStatus(email: string, status: boolean) {
    this.adminUsersService.selectStatus(email, status).subscribe(() => {
      this.loadUsers();
    });
  }
}
