import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import { debounceTime } from 'rxjs/operators';
import { convertDateYearMonth } from '../../modules/acao/utils';

@Component({
  selector: 'app-lines-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LinesChartComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [{ data: [] }];
  public lineChartLabels: Label[] = [];
  @Input() type: string;
  get title() {
    if (this.type == 'rol') {
      return 'Receita Op. Líquida ';
    }
    if (this.type == 'ebitda') {
      return ' Lucro Operacional';
    }
    if (this.type == 'lpa') {
      return ' Lucro por Ação';
    }
    if (this.type == 'dpa') {
      return ' Dividendos por Ação';
    }
    if (this.type == 'patrimonioLiquido') {
      return 'Patrimônio Líquido';
    }
    return 'Outro';
  }
  @Input('data') set setData(value: any) {
   
    if (value == undefined) {
      return;
    }

    if (value.length == 0) {
      return;
    }
    value = JSON.parse(JSON.stringify(value));
    if (this.type == undefined) {
      this.type = 'rol';
    }
   
    this.lineChartData = value.map((acao) => {
      return {
        data: acao.data[this.type].map((item) => {
          if (item.value == undefined) {
            return 0;
          }
          return item.value.toFixed(2);
        }),
        label: acao.data.ticker,
      };
    });
    this.lineChartLabels = value[0].data[this.type].map((item) =>
      convertDateYearMonth(item.title)
    );
  }
  public lineChartOptions: ChartOptions = {
    responsive: true,
    spanGaps: true,
    hover: {
      axis: 'x',
      animationDuration: 0,
    },
    animation: {
      duration: 0,
    },
    layout: {},
    scales: {
      yAxes: [
        {
          ticks: {
            maxTicksLimit: 5,
            fontSize: 12,
            beginAtZero: true,
            fontColor: '#000',
            callback: function (value: number, index, values) {
              return '$' + value.toFixed(2);
            },
          },

          gridLines: {
            offsetGridLines: false,
            display: true,
            color: ['grey'],
            borderDash: [2, 4],
          },
        },
      ],
      xAxes: [
        {
          distribution: 'series',
          ticks: {
            fontSize: 12,
            fontColor: '#000',
            autoSkip: true,
            padding: 0,
            maxRotation: 0,
            maxTicksLimit: 8,
            beginAtZero: false,
            callback: function (value, index, values) {
              return value;
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    elements: {
      line: {
        tension: 0,
        fill: true,
      },
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    tooltips: {
      mode: 'x-axis',
      bodyFontColor: '#000',
      backgroundColor: '#FFF',
      bodyFontFamily: 'Roboto',
      titleFontColor: '#109cf1',
      callbacks: {
        label: (i, b) => {
          let value = Number(i.value).toLocaleString('pt-BR', {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return this.title + ': $f' + value;
        },
      },
    },
    legend: {
      align: 'start',
      display: true,
      labels: {
        boxWidth: 12,
      },
    },
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: '#FFFFFF00',
      borderColor: '#109cf1',
      borderWidth: 2,
      pointHoverRadius: 0,
      pointBorderWidth: 5,
      pointBackgroundColor: '#109cf1',
      pointBorderColor: '#109cf1',
      pointHoverBackgroundColor: '#109cf1',
      pointHoverBorderColor: '#109cf1',
      pointHoverBorderWidth: 4,
    },
    {
      backgroundColor: '#FFFFFF00',
      borderColor: '#398E2C',
      borderWidth: 2,
      pointHoverRadius: 0,
      pointBorderWidth: 5,
      pointBackgroundColor: '#398E2C',
      pointBorderColor: '#398E2C',
      pointHoverBackgroundColor: '#398E2C',
      pointHoverBorderColor: '#398E2C',
      pointHoverBorderWidth: 4,
    },
    {
      backgroundColor: '#FFFFFF00',
      borderColor: '#F39C12',
      borderWidth: 2,
      pointHoverRadius: 0,
      pointBorderWidth: 5,
      pointBackgroundColor: '#F39C12',
      pointBorderColor: '#F39C12',
      pointHoverBackgroundColor: '#F39C12',
      pointHoverBorderColor: '#F39C12',
      pointHoverBorderWidth: 4,
    },
    {
      backgroundColor: '#FFFFFF00',
      borderColor: '#D42126',
      borderWidth: 2,
      pointHoverRadius: 0,
      pointBorderWidth: 5,
      pointBackgroundColor: '#D42126',
      pointBorderColor: '#D42126',
      pointHoverBackgroundColor: '#D42126',
      pointHoverBorderColor: '#D42126',
      pointHoverBorderWidth: 4,
    },
    {
      backgroundColor: '#FFFFFF00',
      borderColor: '#A6CEE3',
      borderWidth: 2,
      pointHoverRadius: 0,
      pointBorderWidth: 5,
      pointBackgroundColor: '#A6CEE3',
      pointBorderColor: '#A6CEE3',
      pointHoverBackgroundColor: '#A6CEE3',
      pointHoverBorderColor: '#A6CEE3',
      pointHoverBorderWidth: 4,
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor() { }

  ngOnInit() {
    this.lineChartData[0].label = this.title;
  }
}
