import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from '../callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root',
})
export class UploadPlanilhaService {
  constructor(public httpClient: HttpClient, public oidcSecurityService: OidcSecurityService, public authFolderService: AuthFolderService,
    ) {
    }
    get token() {
      if (this.authFolderService.authenticated()) {
        return this.authFolderService.getAccess();
      }
      return this.oidcSecurityService.getAccessToken();
    }
  upload(fileToUpload: File) {
    function getBase64(file) {
      return new Observable((sub) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          sub.next(reader.result);
         
        };
        reader.onerror = function (error) {
          console.error('Error: ', error);
        };
      });
    }
    return getBase64(fileToUpload).pipe(
      switchMap((base64Excel: string) => {
        base64Excel = base64Excel.split('base64,')[1];
        return this.httpClient.post(
          environment.url + 'main/indexer/send/excel',
          { base64Excel },
          {
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          }
        );
      })
    );
  }
}
